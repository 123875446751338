module.exports = ['common', 'dataContext', 'nomenclatureDataService', 'applicationStateService', annotationsService];

function annotationsService(common, context, nomenclatureDataService, state) {
    var service = {
        getSectionNote: getSectionNote,
        getChapterNote: getChapterNote,
        getSubchapterNote: getSubchapterNote,
        searchNotes: searchNotes,
    };

    function getChapterNote(chapterNumber) {
        var url = common.config.apiHosts.generic + '/annotations/chapter/' + chapterNumber +
            '?lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function getSectionNote(sectionNumber) {

        if (state.filter.referenceCountry === "GB") {
            return getGbSectionNote(sectionNumber);
        }

        var url = common.config.apiHosts.generic + '/annotations/section/' + sectionNumber +
            '?lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function getGbSectionNote(sectionNumber) {
        return nomenclatureDataService
            .getSections()
            .then(function(sectionsData) {
                var section = _.find(sectionsData.data, function (section) {
                    return section.number === sectionNumber;
                });
                return {
                    NoteHtml: section.uk_section_note
                };
            })
    }

    function getSubchapterNote(code) {
        var url = common.config.apiHosts.generic + '/annotations/subchapter/' + code +
            '?lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function searchNotes(query) {
        var url = common.config.apiHosts.generic + '/annotations/search' +
            '?q=' + query +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    return service;
}