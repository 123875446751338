﻿module.exports = ['$analytics', '$uibModal', explanatoryNoteModal];

function explanatoryNoteModal($analytics, $uibModal) {
    var modal = {
        goodsCode: null,
        explanatoryNote: null,
        show: showModal
    };

    modalExplanatoryNoteController.$inject = [
        '$sce', 
        '$uibModalInstance',
        'applicationStateService', 
        'language', 
        'common', 
        'explanatoryNote', 
        'goodsCode', 
        'textDataService', 
        'nomenclatureDataService', 
        'identity'
    ];

    function modalExplanatoryNoteController(
        $sce, 
        $uibModalInstance, 
        state,
        language, 
        common, 
        explanatoryNote, 
        goodsCode, 
        textDataService, 
        nomenclatureDataService, 
        identity
    ) {
        var vm = {
            cancel: cancel,
            clickSectionNavItem: clickSectionNavItem,
            formatGermanDocumentDescription: formatGermanDocumentDescription,
            data: {
                currentNote: null,
                cccConclusions: [],
                englishClassificationsNew: [],
                englishClassificationsOld: [],
                notes: [],
                otherClassifications: [],
                sections: [],
                tariferingen: [],
                wcoClassificationOpinions: []
            },
            explanatoryNote: explanatoryNote,
            goodsCode: goodsCode,
            identity: identity,
            isLoading: true,
            language: language.get(),
            now: new Date(),
            ok: ok,
            translations: []
        };
        var levelOrder = ['section', 'chapter', 'subchapter', 'heading'];

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function clickSectionNavItem(item) {
            document.querySelector(item.selector).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        function formatGermanDocumentDescription(description) {
            switch(description) {
                case 'AV':
                    return 'Avise zum Harmonisierten System';
                case 'EE':
                    return 'Einzelentscheidungen zur Kombinierten Nomenklatur';
                case 'GE - EuGH':
                    return 'Gerichtliche Entscheidungen - EuGH';
                case 'GE - national':
                    return 'Gerichtliche Entscheidungen - national';
                case 'HS':
                    return 'Erläuterungen zum Harmonisierten System';
                case 'KN':
                    return 'Erläuterungen zur Kombinierten Nomenklatur';
                case 'NEH':
                    return 'Nationale Entscheidungen und Hinweise';
                default:
                    return description;
            }
        }

        function formatHtml(note) {
            if (!note)
                return null;

            note = note.replace(/(?:\\[rn])+/g, " ");
            while (note.indexOf('\\"') > -1)
                note = note.replace('\\"', '"');

            note = $sce.trustAsHtml(note);
            return note.toString();
        }

        function getLevel(goodsCode) {
            var level;
            if (goodsCode[0] === 'a') {
                level = 'section';
            }
            else if (goodsCode[0] === 'h') {
                level = 'chapter';
            }
            else if (goodsCode.substring(goodsCode.length - 3) === 'sub') {
                level = 'subchapter';
            }
            else {
                level = 'heading';
            }
            return level;
        }

        function getGoodsCodeOrSectionNumber(goodsCode) {
            var result;
            if (goodsCode[0] === 'a') {
                result = goodsCode.substring(1);
            }
            else if (goodsCode[0] === 'h') {
                result = goodsCode.substring(1);
            }
            else if (goodsCode.substring(goodsCode.length - 3) === 'sub') {
                result = goodsCode.substring(0, goodsCode.length - 4);
            }
            else {
                result = goodsCode.substring(0, 4);
            }
            return result;
        }

        function loadWcoExplanatoryNotes(level, goodsCodeOrSectionNumber) {
            var deferred = common.$q.defer();
            if (identity.hasPermission('toelichtingen_wco')) {
                textDataService.getWcoExplanatoryNotes(level, goodsCodeOrSectionNumber)
                    .then(function(notes) {
                        _.forEach(notes, function(note) {
                            addExplanatoryNote(note, 'wco');
                        });
                        deferred.resolve();
                    });
            }
            else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        function loadEuExplanatoryNotes(level, goodsCodeOrSectionNumber) {
            var deferred = common.$q.defer();
            if (identity.hasPermission('toelichtingen_eu')) {
                textDataService.getEuExplanatoryNotes(level, goodsCodeOrSectionNumber)
                    .then(function(notes) {
                        _.forEach(notes, function(note) {
                            addExplanatoryNote(note, 'eu');
                        });
                        deferred.resolve();
                    });
            }
            else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        function loadGermanExplanatoryNotes(level, goodsCodeOrSectionNumber) {
            return textDataService.getGermanExplanatoryNotes(level, goodsCodeOrSectionNumber)
                .then(function(result) {
                    vm.germanExplanatoryNotes = result;
                });
        }

        function init() {
            if (!vm.goodsCode)
                return;

            var level = getLevel(vm.goodsCode);
            var goodsCodeOrSectionNumber = getGoodsCodeOrSectionNumber(vm.goodsCode);

            var promises = [
                loadWcoExplanatoryNotes(level, goodsCodeOrSectionNumber)
            ];
            
            if (state.filter.referenceCountry !== 'CH' && state.filter.referenceCountry !== 'US') {
                promises.push(loadEuExplanatoryNotes(level, goodsCodeOrSectionNumber));
            }

            if (state.filter.referenceCountry === 'DE') {
                promises.push(loadGermanExplanatoryNotes(level, goodsCodeOrSectionNumber));
            }


            common.$q.all(promises).then(function() {
                vm.isLoading = false;
            });

            // if (identity.hasPermission('toelichtingen_wco')) {
            //     textDataService.getWcoExplanatoryNotes(level, goodsCodeOrSectionNumber)
            //         .then(function(notes) {
            //             _.forEach(notes, function(note) {
            //                 addExplanatoryNote(note, 'wco');
            //             });
            //         });
            // }
            // if (identity.hasPermission('toelichtingen_eu')) {
            //     textDataService.getEuExplanatoryNotes(level, goodsCodeOrSectionNumber)
            //         .then(function(notes) {
            //             _.forEach(notes, function(note) {
            //                 addExplanatoryNote(note, 'eu');

            //             });
            //         });
            // }

            if (level == 'heading') {

                if (common.language.get() === 'nl') {
                    // nieuwe tariferingen uit database
                    textDataService.getTariferingen(vm.goodsCode)
                        .then(function (tariferingen) {
                            vm.data.tariferingen = tariferingen;
                            if (tariferingen.length > 0) {
                                vm.data.sections.push({
                                    title: 'nomenclature.Classifications',
                                    selector: '.tariferingen',
                                    index: 3
                                });
                            }
                        });

                    // overige indelingsverordeningen
                    textDataService.getClassificationOther(goodsCode)
                        .then(function (classifications) {
                            if (classifications.length > 0) {
                                vm.data.sections.push({
                                    title: 'classificationRegulation.ClassificationRegulations',
                                    selector: '.classification-regulations',
                                    index: 4
                                });
                            }
                            vm.data.otherClassifications = classifications;
                        });
                }
                else {

                    textDataService.getOldEnglishClassifications(goodsCode)
                        .then(function (data) {
                            if (data.length > 0) {
                                vm.data.sections.push({
                                    title: 'Classification Regulations (before 1-1-2014)',
                                    selector: '.classification-regulations-old h1',
                                    index: 3
                                });
                            }
                            vm.data.englishClassificationsOld = data;
                        });

                    textDataService.getNewEnglishClassifications(goodsCode)
                        .then(function (data) {
                            if (data.length > 0) {
                                vm.data.sections.push({
                                    title: 'Classification Regulations (from 1-1-2014)',
                                    selector: '.classification-regulations-new h1',
                                    index: 4
                                });
                            }
                            vm.data.englishClassificationsNew = data;
                        });

                    textDataService.getCccConclusions(goodsCode)
                        .then(function (data) {
                            if (data.length > 0) {
                                vm.data.sections.push({
                                    title: 'conclusion.CccConclusions',
                                    selector: '.ccc-conclusions',
                                    index: 8
                                });
                            }
                            vm.data.cccConclusions = data;
                        });
                }
            }
            if (identity.hasPermission('toelichtingen_wco')) {
                textDataService.getWcoClassificationOpinions(goodsCode)
                    .then(function (result) {
                        if (result.Opinions.length > 0) {
                            vm.data.sections.push({
                                title: 'nomenclature.WcoClassificationOpinions',
                                selector: '.wco-classification-opinions',
                                index: 7
                            });
                        }
                        vm.data.wcoClassificationOpinions = result;
                    });
            }
        }

        function makeSectionTitle(subfolder) {
            subfolder = subfolder.toLowerCase();
            switch (subfolder) {
                case 'wco':
                    return 'nomenclature.ExplanatoryNotesHs';
                case 'eu':
                    return 'nomenclature.ExplanatoryNotesCn';
                default:
                    return 'nomenclature.ExplanatoryNotes' + ' ' + subfolder;
            }
        }

        function ok() {
            $uibModalInstance.close(true);
        }

        function makeTitle(nomenclatureLevel, goodsCodeOrSectionNumber, number) {
            switch(nomenclatureLevel) {
                case 'section':
                    return 'nomenclature.Section_GoodsCodeOrSectionNumber_';
                case 'chapter':
                    return 'nomenclature.Chapter_GoodsCodeOrSectionNumber_';
                case 'subchapter':
                    return 'nomenclature.SubChapter_GoodsCodeOrSectionNumber_';
                case 'heading':
                default:
                    return 'nomenclature.Heading_GoodsCodeOrSectionNumber_';
            }
        }

        function getSubchapterNumber(note) {
            var deferred = common.$q.defer();
            if (note.NomenclatureLevel === 'SUBCHAPTER') {
                var chapterNumber = note.GoodsCodeOrSectionNumber.substring(0, 2);
                nomenclatureDataService
                    .getSubchapterNumber(chapterNumber, note.GoodsCodeOrSectionNumber)
                    .then(function(number) {
                        deferred.resolve(number);
                    });
            }
            else {
                deferred.resolve(null);
            }
            return deferred.promise;
        }

        function addExplanatoryNote(note, subfolder) {
            if (!note) {
                return;
            }

            getSubchapterNumber(note)
                .then(function(subchapterNumber) {
                    var level = note.NomenclatureLevel.toLowerCase();

                    var sectionTitle = makeSectionTitle(subfolder);
                    var title = makeTitle(level, note.GoodsCodeOrSectionNumber, subchapterNumber);
                    var number = (level === 'subchapter' ? subchapterNumber : note.GoodsCodeOrSectionNumber);

                    var extraData = {
                        subfolder: subfolder,
                        sectionTitle: sectionTitle,
                        title: title,
                        level: level,
                        goodsCode: number,
                        NoteHtml: formatHtml(note.NoteHtml)
                    }
                    var noteWithExtraData = _.assign(
                        {}, note, extraData
                    );
                    vm.data.notes.push(noteWithExtraData);

                    var subsection = {
                        title: title,
                        subfolder: subfolder,
                        goodsCode: number,
                        level: level,
                        selector: "." + subfolder + "-note-" + number,
                        index: levelOrder.indexOf(level)
                    };
                    var section = _.find(vm.data.sections, function (section) {
                        return section.title === sectionTitle;
                    });
                    if (section === undefined) {
                        section = {
                            title: sectionTitle,
                            subfolder: subfolder,
                            level: level,
                            selector: "." + subfolder + "-note",
                            index: subfolder.toLowerCase() === 'wco' ? 1 : 2,
                            subsections: []
                        };
                        vm.data.sections.push(section);
                    }
                    section.subsections.push(subsection);

                    if (vm.data.notes.length > 1) {
                        vm.data.notes = _.sortBy(vm.data.notes, sortNotes);
                        vm.data.sections = _.sortBy(vm.data.sections, sortNotes);
                    }
                });
        }

        function sortNotes(note) {
            var mainOrderList = ["wco", "eu"]; // WCO notes first
            var subOrderList = ['section', 'chapter', 'subchapter', 'heading'];
            var mainOrder = note.subfolder ? mainOrderList.indexOf(note.subfolder.toLowerCase()) : 1000;
            if (mainOrder === -1) {
                mainOrder = 1000; // if note.subfolder is not found in the list above, put those notes at the end
            }
            var subOrder = subOrderList.indexOf(note.level);
            return mainOrder * 100 + subOrder;
        }

        init();

        return vm;
    }

    function showModal(goodsCode, explanatoryNote) {
        $analytics.eventTrack('open-modal-explanatory-notes', { category: 'explanatory-notes' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: modalExplanatoryNoteController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                explanatoryNote: function () {
                    return explanatoryNote;
                },
                goodsCode: function () {
                    return goodsCode;
                }
            },
            size: 'lg',
            templateUrl: 'texts/modals/explanatory-note.html',
            windowClass: 'modal-explanatory-note'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
