﻿var moment = require('moment');

module.exports = [
    '$analytics', 
    '$location', 
    '$q',
    '$routeParams', 
    '$timeout', 
    '$window',
    'additionalCodesDataService',
    'common',
    'dutyDataService', 
    'eztDataService',
    'favoritesService', 
    'goodDataService', 
    'goodDisplayService', 
    'goodModals', 
    'goodModelsService', 
    'transactionValueDataService', 
    'transactionValueCalculatorModal',
    'countryDataService',
    'nomenclatureDataService', 
    'preferenceCodeDataService', 
    'preferenceService', 
    'measureGroupingService', 
    'notificationsService', 
    'applicationStateService', 
    'ecicsDataService',
    'tradeStatisticsDataService', 
    goodController
];

function goodController(
    $analytics, 
    $location, 
    $q, 
    $routeParams, 
    $timeout, 
    $window,
    additionalCodesDataService,
    common,
    dutyDataService, 
    eztDataService,
    favoritesService, 
    goodDataService, 
    goodDisplayService, 
    goodModals, 
    goodModelsService, 
    transactionValueDataService, 
    transactionValueCalculatorModal,
    countryDataService,
    nomenclatureDataService, 
    preferenceCodeDataService, 
    preferences, 
    measureGroupingService, 
    notificationsService, 
    state,
    ecicsDataService,
    tradeStatisticsDataService
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        chapter: null,
        collapseMeasures: goodDisplayService.collapseMeasures,
        currentTab: 'show-measures',
        ergaOmnesCountries: countryDataService.ergaOmnesCountries,
        expandMeasures: goodDisplayService.expandMeasures,
        goHome: function () {
            $location.url('');
        },
        validationErrors: {
            quantities: {}
        },
        good: {
            generalInfo: {
                code: ''
            },
            measures: new goodModelsService.measuresDisplayModel(),
            nomenclatureGroups: []
        },
        hasAutonomousTariffSuspension: false,
        hasMeursing: false,
        isLoading: {
            generalInfo: true,
            meursingHeaders: true,
            measures: true
        },
        customsValue: {},
        customsValueCalculationMethod: 'calculate-value',
        applicableAntiDumpingAdditionalCodes: [],
        otherApplicableAdditionalTaricCodes: [],
        applicableNationalAdditionalCodes: [],
        antiDumpingAdditionalCode: null,
        otherAdditionalTaricCodes: {},
        meursingAdditionalCode: null,
        nationalAdditionalCode: null,
        certificateCodes: [],
        orderNumber: null,
        applicableOrderNumbers: [],
        preferenceCode: null,
        possiblePreferenceCodes: [],
        showUccToggle: false,
        quantities: [],
        section: null,
        numberOfEcicsItems: 0,
        state: state,
        anAdditionalCodeHasBeenSet: anAdditionalCodeHasBeenSet,
        calculateDuty: calculateDuty,
        evaluateMeasureConditions: evaluateMeasureConditions,
        countryPickerSelectionChanged: countryPickerSelectionChanged,
        goToNomenclature: goToNomenclature,
        onChangeCertificateCodes: onChangeCertificateCodes,
        onChangeAntidumpingAdditionalCode: onChangeAntidumpingAdditionalCode,
        onChangeDutyMeasureType: onChangeDutyMeasureType,
        onChangeEztExciseCode: onChangeEztExciseCode,
        onChangeNationalAdditionalCode: onChangeNationalAdditionalCode,
        onChangeSecondNationalAdditionalCode: onChangeSecondNationalAdditionalCode,
        onChangeOtherAdditionalCode: onChangeOtherAdditionalCode,
        onChangeOrderNumber: onChangeOrderNumber,
        onChangeOutputCurrency: onChangeOutputCurrency,
        onChangePreferenceCode: onChangePreferenceCode,
        showMeursingAdditionalCodeModal: showMeursingAdditionalCodeModal,
        showVerbruiksbelastingWarning: showVerbruiksbelastingWarning,
        loadMeursingTariffs: loadMeursingTariffs,
        changeTab: changeTab,
        selectEztNomenclature: selectEztNomenclature,
        showTransactionValueCalculator: showTransactionValueCalculator,
        validateDeclaration: validateDeclaration,
        uccSettingChanged: uccSettingChanged
    };


    var antiDumpingAdditionalCodeTypes = ['8', 'A', 'B', 'C'];
    var otherAdditionalTaricCodeTypes = ['2', '3', '4', '6', '9'];
    var nationalAdditionalCodeTypes = ['0', '1', '5', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    function uccSettingChanged(uccModeEnabled) {
        preferences.replace({
            category: 'settings',
            name: 'uccModeEnabled',
            value: uccModeEnabled,
            valueType: 'stringValue'
        });
        vm.state.uccModeEnabled = uccModeEnabled;
        vm.onChangePreferenceCode(null);
    }

    function countryPickerSelectionChanged(country, rememberCountry) {
        clearMeasureConditionEvaluation();
        vm.customsValueCheckResult = null;
        var countryHasChanged = state.countryHasChanged(country);
        state.updateCountryFilter(country, rememberCountry);
        if (rememberCountry && country) {
            preferences.saveCountryPreference(country);
        } else {
            preferences.clearCountryPreference();
        }
        if (countryHasChanged) {
            filterMeasuresByCountry();
        }
    }

    function showTransactionValueCalculator() {
        transactionValueCalculatorModal
            .show()
            .then(function(result) {
                if (result) {
                    vm.customsValue = result;
                }
            })
            .catch(function(error) {
            });
    }

    function changeTab(tabId) {
        notificationsService.publish('declarable-code-change-tab');
        switch (tabId) {
            case 'show-measures':
                $analytics.pageTrack('/show-measures');
                vm.currentTab = 'show-measures';
                break;
            case 'show-hinweise':
                $analytics.pageTrack('/show-hinweise');
                vm.currentTab = 'show-hinweise';
                break;
            case 'validate-declaration':
                $analytics.pageTrack('/validate-declaration');
                vm.currentTab = 'validate-declaration';
                break;
            case 'calculate-duty':
                $analytics.pageTrack('/calculate-duty');
                vm.currentTab = 'calculate-duty';
                break;
        }
    }

    function onChangeCertificateCodes(certificateCodes) {
        vm.certificateCodes = certificateCodes;
    }

    function onChangeOutputCurrency(currency) {
        if (currency) {
            loadGood(vm.good.generalInfo.code, vm.nationalAdditionalCode, vm.meursingAdditionalCode, currency);
        }
        else {
            loadGood(vm.good.generalInfo.code, vm.nationalAdditionalCode, vm.meursingAdditionalCode);
        }
        vm.outputCurrency = currency;
    }

    function onChangeAntidumpingAdditionalCode(additionalCode) {
        // console.log('goodsController::onChangeAntidumpingAdditionalCode', additionalCode);
        vm.antiDumpingAdditionalCode = additionalCode;
        filterMeasures();
    }

    function onChangeOtherAdditionalCode(type, additionalCode) {
        // console.log('goodsController::onChangeOtherAdditionalCode', type, additionalCode);
        if (additionalCode) {
            vm.otherAdditionalTaricCodes[type] = additionalCode;
        }
        else if (type) {
            delete vm.otherAdditionalTaricCodes[type];
        }
        filterMeasures();
    }

    // TODO: save additional codes as object (or as string, but consistently - right now antidumping is saved as object)
    function onChangeNationalAdditionalCode(additionalCode) {
        var previousAdditionalCodeIsOptional = state.filter.referenceCountry === 'NL' &&
            !!vm.nationalAdditionalCodeObject &&
            (vm.nationalAdditionalCodeObject.type.toUpperCase() === 'T' ||
                vm.nationalAdditionalCodeObject.type.toUpperCase() === 'V');

        var additionalCodeIsOptional = state.filter.referenceCountry === 'NL' &&
            !!additionalCode &&
            (additionalCode.type.toUpperCase() === 'T' || additionalCode.type.toUpperCase() === 'V');

        if (additionalCode) {
            vm.nationalAdditionalCode = additionalCode.type + additionalCode.code;
            vm.nationalAdditionalCodeObject = additionalCode;
        }
        else {
            vm.nationalAdditionalCode = null;
            vm.nationalAdditionalCodeObject = null;
        }

        var shouldLoadOptionalAdditionalCodes = (additionalCodeIsOptional && !previousAdditionalCodeIsOptional) ||
            (!additionalCodeIsOptional && previousAdditionalCodeIsOptional);

        if (shouldLoadOptionalAdditionalCodes) {
            $q.all([
                loadGood(vm.good.generalInfo.code, vm.nationalAdditionalCode, vm.meursingAdditionalCode, vm.outputCurrency),
                loadUnits()
            ]);
        }
        else {
            filterMeasures();
        }
    }

    // TODO: save additional codes as object (or as string, but consistently - right now antidumping is saved as object)
    function onChangeSecondNationalAdditionalCode(additionalCode) {
        if (additionalCode) {
            vm.secondNationalAdditionalCode = additionalCode.type + additionalCode.code;
            vm.secondNationalAdditionalCodeObject = additionalCode;
        }
        else {
            vm.secondNationalAdditionalCode = null;
            vm.secondNationalAdditionalCodeObject = null;
        }
        filterMeasures();
    }

    function onChangeDutyMeasureType(dutyMeasureType) {
        var measurePreferenceCodesProperty = vm.state.uccModeEnabled === 'yes' ? 'ucc_preference_codes' : 'preference_codes';
        vm.dutyMeasureType = dutyMeasureType;
        if (!dutyMeasureType) {
            vm.preferenceCode = null;
        }
        else if (vm.preferenceCode && dutyMeasureType[measurePreferenceCodesProperty].indexOf(vm.preferenceCode.code) === -1) {
            vm.preferenceCode = null
        }

        // TODO: check if this should be moved to loadMeasuresCompleted, so that we
        // also perform this action if the duty measure type is changed for another reason
        if (dutyMeasureType && dutyMeasureType[measurePreferenceCodesProperty] && dutyMeasureType[measurePreferenceCodesProperty].length === 1) {
            vm.preferenceCode = _.find(vm.preferenceCodes, function (preferenceCode) {
                return preferenceCode.code === dutyMeasureType[measurePreferenceCodesProperty][0];
            });
        }

        filterMeasures();
    }

    function onChangePreferenceCode(preferenceCode) {
        var measurePreferenceCodesProperty = vm.state.uccModeEnabled === 'yes' ? 'ucc_preference_codes' : 'preference_codes';
        vm.preferenceCode = preferenceCode;
        if (!preferenceCode) {
            vm.dutyMeasureType = null;
        }
        else if (!vm.dutyMeasureType) {
            let dutyMeasureTypes = _.filter(vm.applicableDutyMeasureTypes, function (dutyMeasureType) {
                return dutyMeasureType[measurePreferenceCodesProperty].indexOf(preferenceCode.code) > -1;
            });
            if (dutyMeasureTypes.length === 1) {
                vm.dutyMeasureType = dutyMeasureTypes[0];
            }
        }
        else if (vm.dutyMeasureType && vm.dutyMeasureType[measurePreferenceCodesProperty].indexOf(preferenceCode.code) === -1) {
            vm.dutyMeasureType = null
        }
        
        filterMeasures();
    }

    function onChangeOrderNumber(orderNumber) {
        vm.orderNumber = orderNumber;
        filterMeasures();
    }

    function collectAdditionalCodes() {
        var additionalCodes = _.values(vm.otherAdditionalTaricCodes);
        if (vm.antiDumpingAdditionalCode) {
            additionalCodes.push(vm.antiDumpingAdditionalCode);
        }
        if (vm.meursingAdditionalCode) {
            additionalCodes.push({
                type: vm.meursingAdditionalCode.substring(0, 1),
                code: vm.meursingAdditionalCode.substring(1)
            });
        }
        if (vm.nationalAdditionalCodeObject) {
            additionalCodes.push(vm.nationalAdditionalCodeObject);
        }
        if (vm.secondNationalAdditionalCodeObject) {
            additionalCodes.push(vm.secondNationalAdditionalCodeObject);
        }
        return additionalCodes;
    }

    function setAntiDumpingAdditionalCodeToDefaultValue() {
        vm.antiDumpingAdditionalCode = _.find(vm.applicableAntiDumpingAdditionalCodes, function(additionalCode) {
            return (additionalCode.type === "8" && additionalCode.code === "900") ||
                (additionalCode.type === "A" && additionalCode.code === "999") ||
                (additionalCode.type === "B" && additionalCode.code === "999") ||
                (additionalCode.type === "C" && additionalCode.code === "999")
            ;
        });
    }
    function setPreferenceCodeToDefaultValue() {
        var thirdCountryDutyPreferenceCode = _.find(vm.possiblePreferenceCodes, function(preferenceCode) {
            return preferenceCode.code === '100';
        });
        var endUsePreferenceCode = _.find(vm.possiblePreferenceCodes, function(preferenceCode) {
            return preferenceCode.code === '140';
        });
        if (thirdCountryDutyPreferenceCode) {
            vm.preferenceCode = thirdCountryDutyPreferenceCode;
            let defaultDutyMeasureType = _.find(vm.applicableDutyMeasureTypes, function (dutyMeasureType) {
                return dutyMeasureType.id === '103';
            });
            if (defaultDutyMeasureType !== null) {
                vm.dutyMeasureType = defaultDutyMeasureType;
            }
        }
        else if (endUsePreferenceCode) {
            vm.preferenceCode = endUsePreferenceCode;
        }
    }

    function validateDeclaration() {
        vm.customsValueCheckResult = null;
        $analytics.eventTrack('submit-validate-declaration'); 
        notificationsService.publish('submit-validate-declaration');
        checkCustomsValue();
        evaluateMeasureConditions();
    }

    function checkCustomsValue() {
        var netWeight = _.find(vm.quantities, function(quantity) {
            return quantity.UnitType && quantity.UnitType.Type === 'Weight';
        });
        if (netWeight && netWeight.Amount && vm.customsValue.amount && state.filter.countryCode) {
            var query = {
                commodityCode: vm.good.generalInfo.code,
                customsValue: vm.customsValue,
                date: state.filter.date,
                partnerCountry: state.filter.countryCode,
                quantities: vm.quantities,
                reportingCountry: state.filter.referenceCountry,
                tradeMovement: state.filter.movement, // TODO: process trade movement in API
            };
            if (query.reportingCountry !== 'EU' && query.reportingCountry !== 'CH' && query.reportingCountry !== 'GB') {
                tradeStatisticsDataService.checkCustomsValue(query)
                    .then(function (result) {
                        vm.customsValueCheckResult = result;
                    });
            }
        }
        else {
            vm.customsValueCheckResult = null;
        }
    }

    function clearMeasureConditionEvaluation() {
        vm.measures = _.map(vm.measures, function (measure) {
            measure.conditionEvaluation = null;
            return measure;
        })
        vm.good.measures = measureGroupingService.group(vm.measures, state.filter.referenceCountry);
    }

    function evaluateMeasureConditions() {
        vm.isLoading.evaluateMeasureConditions = true;
        var additionalCodes = collectAdditionalCodes();
        if (vm.dutyMeasureType) {
            var dutyMeasureTypeId = vm.dutyMeasureType.id;
        }
        var query = {
            certificateCodes: vm.certificateCodes,
            commodityCode: vm.good.generalInfo.code,
            additionalCodes: additionalCodes,
            preferenceCode: vm.preferenceCode,
            uccModeEnabled: vm.state.uccModeEnabled,
            customsValue: vm.customsValue,
            dutyMeasureTypeId: dutyMeasureTypeId,
            orderNumber: vm.orderNumber,
            quantities: vm.quantities,
            countryCode: state.filter.countryCode,
            tradeMovement: state.filter.movement,
        };
        return goodDataService
            .evaluateMeasureConditions(query)
            .then(function(results) {
                vm.isLoading.evaluateMeasureConditions = false;

                vm.measures = _.map(vm.measures, function (measure) {
                    var result = results[measure.id];
                    if (result) {
                        measure.conditionEvaluation = result;
                    }
                    return measure;
                })
                vm.good.measures = measureGroupingService.group(vm.measures, state.filter.referenceCountry);
            });
    }

    function setBritishVatCodeToOnlyPossibleValueIfNecessary() {
        if (state.filter.referenceCountry === 'GB') {
            if (vm.applicableNationalAdditionalCodes && vm.applicableNationalAdditionalCodes.length === 1 && !vm.nationalAdditionalCode) {
                var applicableNationalAdditionalCode = vm.applicableNationalAdditionalCodes[0];
                var vatMeasures = _.filter(vm.measures, function (measure) {
                    return measure.series === 'P';
                });
                if (vatMeasures.length === 1) {
                    var vatMeasure = vatMeasures[0];
                    if (!!vatMeasure.additional_code && vatMeasure.additional_code.type === applicableNationalAdditionalCode.type && vatMeasure.additional_code.code === applicableNationalAdditionalCode.code) {
                        vm.nationalAdditionalCodeObject = applicableNationalAdditionalCode;
                        vm.nationalAdditionalCode = applicableNationalAdditionalCode.type + applicableNationalAdditionalCode.code;
                    }
                }
                
            }
        }
    }

    function calculateDuty() {
        notificationsService.publish('submit-calculate-duty');
        $analytics.eventTrack('submit-calculate-duty');
        vm.dutyCalculationResult = null;
        vm.dutyCalculationErrors = null;
        vm.isLoading.dutyCalculation = true;
        var additionalCodes = collectAdditionalCodes();
        if (vm.dutyMeasureType) {
            var dutyMeasureTypeId = vm.dutyMeasureType.id;
        }
        var germanExciseCode = vm.eztExciseCode ? vm.eztExciseCode.Id : null;

        if (!vm.outputCurrency) {
            vm.outputCurrency = _.find(vm.possibleUnitsForCurrencyConversion, function (currency) {
                return currency.code === vm.monetaryUnit;
            });
        }

        var query = {
            certificateCodes: vm.certificateCodes,
            commodityCode: vm.good.generalInfo.code,
            currencyCode: vm.outputCurrency.code,
            additionalCodes: additionalCodes,
            preferenceCode: vm.preferenceCode,
            uccModeEnabled: vm.state.uccModeEnabled,
            customsValue: vm.customsValue,
            dutyMeasureTypeId: dutyMeasureTypeId,
            orderNumber: vm.orderNumber,
            quantities: vm.quantities,
            countryOfOrigin: state.filter.countryCode,
            germanNationalCommodityCode: vm.currentEztNationalCommodityCode,
            germanExciseCode: germanExciseCode
        };
        vm.validationErrors = {
            quantities: {}
        };
        checkCustomsValue();
        return dutyDataService
            .calculateDuty(query)
            .then(function(result) {
                var shouldFilterMeasures = false;
                vm.isLoading.dutyCalculation = false;
                if (vm.applicableAntiDumpingAdditionalCodes.length > 0 && !vm.antiDumpingAdditionalCode) {
                    shouldFilterMeasures = true;
                    setAntiDumpingAdditionalCodeToDefaultValue();
                }
                setBritishVatCodeToOnlyPossibleValueIfNecessary();
                if (vm.possiblePreferenceCodes.length > 0 && !vm.preferenceCode && !vm.dutyMeasureType) {
                    shouldFilterMeasures = true;
                    setPreferenceCodeToDefaultValue();
                }
                if (shouldFilterMeasures) {
                    filterMeasures().then(function(){
                        vm.dutyCalculationResult = result;
                    });
                }
                else {
                    vm.dutyCalculationResult = result;
                }
            }, function (error) {
                if (error && error.data) {
                    _.forEach(error.data.Errors, function(errorMessage) {
                        if (errorMessage.Code.indexOf('customs_value') !== -1) {
                            vm.validationErrors.customsValue = true;
                        }
                        else if (errorMessage.Code.indexOf('country_of_origin') !== -1) {
                            vm.validationErrors.countryOfOrigin = true;
                        }
                        else if (errorMessage.Code === 'quantity_missing') {
                            let unitPlusQuantifier = errorMessage.Message.split(' (')[0];
                            vm.validationErrors.quantities[unitPlusQuantifier] = true;
                            // Exclude retail price errors
                            if (unitPlusQuantifier !== '003' && unitPlusQuantifier !== 'RET') {
                                vm.validationErrors.hasQuantitiesError = true;
                            }
                        }
                        else if (errorMessage.Code === 'quantities_parse_error') {
                            var messageParts = errorMessage.Message.split(' ');
                            let unitPlusQuantifier;
                            if (messageParts[messageParts.length - 1].length === 1) {
                                unitPlusQuantifier = messageParts[messageParts.length - 2] + ' ' + messageParts[messageParts.length - 1];
                            }
                            else {
                                unitPlusQuantifier = messageParts[messageParts.length - 1];
                            }
                            vm.validationErrors.quantities[unitPlusQuantifier] = true;
                            // Exclude retail price errors
                            if (unitPlusQuantifier !== '003' && unitPlusQuantifier !== 'RET') {
                                vm.validationErrors.hasQuantitiesError = true;
                            }
                        }
                        else if (errorMessage.Code === 'additional_code_validation_error') {
                            vm.validationErrors.additionalCodes = true;
                        }
                        else if (errorMessage.Code.indexOf('order_number') > -1) {
                            vm.validationErrors.orderNumber = true;
                        }
                        if (errorMessage.Code.indexOf('duty_measure_not_unique_for_preferencecode') !== -1 || errorMessage.Code.indexOf('add_duty_measure_id_for_end_use_choice') !== -1) {
                            vm.validationErrors.dutyMeasureNotUniqueForPreferenceCode = true;
                        }
                        if (errorMessage.Code.indexOf('german_national_commodity_code') !== -1) {
                            vm.validationErrors.germanNationalCommodityCode = true;
                        }
                        if (errorMessage.Code.indexOf('german_excise_code') !== -1) {
                            vm.validationErrors.germanExciseCode = true;
                        }
                        if (errorMessage.Code === 'unsupported_duty_expression') {
                            vm.dutyCalculationErrors = {
                                unsupportedDutyExpression: true
                            };
                        }
                        
                    });
                    vm.isLoading.dutyCalculation = false;
                }
            });
    }

    function filterMeasures() {
        var start = moment();
        vm.isLoading.measures = true;
        vm.validationErrors = {
            quantities: {}
        };
        vm.dutyCalculationResult = null;
        vm.dutyCalculationErrors = null;
        // NB: we add a timeout here, so that the loading indicator shows before angularjs gets busy
        // rendering the measures
       return $timeout(function () {
            vm.good.measures = goodModelsService.measuresDisplayModel();
            return $q.all([
                loadMeasures(state.filter.countryCode),
                loadUnits()
            ]).then(function() {
                return $timeout(function () {
                    vm.isLoading.measures = false;
                    // Uncomment this to see how long it takes to render measures when switching country
                    // console.log(moment().diff(start, 'seconds', true));
                    // console.log('goodsVM', vm);
                }, 0);
            });
        }, 0);
    }

    function filterMeasuresByCountry() {
        var start = moment();
        vm.isLoading.measures = true;
        vm.validationErrors = {
            quantities: {}
        };
        vm.dutyCalculationResult = null;
        // NB: we add a timeout here, so that the loading indicator shows before angularjs gets busy
        // rendering the measures
        return $timeout(function () {
            vm.good.measures = goodModelsService.measuresDisplayModel();
            return $q.all([
                loadMeasures(state.filter.countryCode),
                loadUnits()
            ]).then(function() {
                vm.isLoading.measures = false;
                return $timeout(function () {
                    // Uncomment this to see how long it takes to render measures when switching country
                    // console.log(moment().diff(start, 'seconds', true));
                }, 0);
            })
        }, 0);
    }

    function goToNomenclature(goodsnomid, indentation) {
        var selection = $window.getSelection();
        var textIsSelected = selection.toString().length > 0;
        if (textIsSelected) {
            selection.empty();
        }
        else {
            state.currentCode = goodsnomid;
            state.currentIndentation = indentation;
            state.navigatingFromMeasuresPage = true;
            $location.url('/search/' + goodsnomid);
        }
    }

    function dateChanged() {
        $location.url('/search/' + vm.good.generalInfo.code);
    }

    function loadEcicsCount(goodsCode) {
        return ecicsDataService.countForGoodsCode(goodsCode)
            .then(function(numberOfEcicsItems) {
                vm.numberOfEcicsItems = numberOfEcicsItems;
            });
    }

    var verbruiksbelastingHeadings = ['2009', '2106', '2201', '2202'];
    function showVerbruiksbelastingWarning() {
        return moment(state.filter.date).year() <= 2019 &&
            state.filter &&
            state.filter.referenceCountry === 'NL' &&
            verbruiksbelastingHeadings.indexOf(vm.good.generalInfo.code.substring(0, 4)) > -1;
    }

    function setMonetaryUnit() {
        if (state.filter.referenceCountry === 'GB' && moment(state.filter.date).isAfter('2021-01-01')) {
            vm.monetaryUnit = 'GBP';
        }
        else {
            vm.monetaryUnit = 'EUR';
        }
        vm.customsValue.unit = vm.monetaryUnit;
        vm.customsValue.originalUnit = vm.monetaryUnit;
        vm.customsValue.exchangeRate = 1;
    }

    function attached() {
        if (state.filter.referenceCountry == 'CH') {
            $location.path('/tares-measures/' + $routeParams.goodsnom);
            return;
        }
        else if (state.filter.referenceCountry == 'US') {
            $location.path('/us-measures/' + $routeParams.goodsnom);
            return;
        }
        $analytics.pageTrack('/show-measures');
        common.auth.check();
        if ($location.search().country) {
            vm.state.filter.countryCode = $location.search().country;
        }

        if ($routeParams.goodsnom !== null && $routeParams.goodsnom !== 'undefined') {
            clearGood();
            vm.good.generalInfo.code = $routeParams.goodsnom;
            state.currentCode = vm.good.generalInfo.code;
            setMonetaryUnit();
            transactionValueDataService.initTransactionValue();

            if (state.filter.movement === 'import') {

                var importPromises = [
                    loadDescription(vm.good.generalInfo.code),
                    loadGood(vm.good.generalInfo.code, vm.nationalAdditionalCode, vm.meursingAdditionalCode, vm.outputCurrency),
                    loadNomenclatureGroups($routeParams.goodsnom),
                    loadSection(vm.good.generalInfo.code),
                    loadOptionalNationalAdditionalCodes(),
                    loadAdditionalCodeTypes(),
                    loadPreferenceCodes(),
                    loadUnits(),
                    loadEcicsCount(vm.good.generalInfo.code)
                ];
                if (vm.state.filter.referenceCountry === 'DE') {
                    importPromises.push(loadGermanNationalNomenclature(vm.good.generalInfo.code));
                    importPromises.push(loadEztHinweise(vm.good.generalInfo.code));
                }

                $q.all(importPromises);

            }
            else if (state.filter.movement === 'export') {

                var exportPromises = [
                    loadDescription(vm.good.generalInfo.code),
                    loadGood(vm.good.generalInfo.code, vm.nationalAdditionalCode, vm.meursingAdditionalCode, vm.outputCurrency),
                    loadNomenclatureGroups($routeParams.goodsnom),
                    loadSection(vm.good.generalInfo.code),
                    loadAdditionalCodeTypes(),
                    loadUnits(),
                    loadEcicsCount(vm.good.generalInfo.code)
                ];
                if (vm.state.filter.referenceCountry === 'DE') {
                    exportPromises.push(loadEztHinweise(vm.good.generalInfo.code));
                }
                $q.all(exportPromises);
            }
        }
        else {
            $location.url('');
        }
        notificationsService.subscribe('filter_movement_changed', movementChanged);
        notificationsService.subscribe('filter_date_changed', dateChanged);
        notificationsService.subscribe('language_changed', languageChanged);
    }

    function loadEztHinweise(commodityCode) {
        eztDataService.loadEztHinweise(commodityCode)
            .then(function (eztHinweise) {
                vm.eztHinweise = eztHinweise;
                vm.exciseHinweise = _.filter(vm.eztHinweise, function (hinweise) {
                    return hinweise.ShortDescription === 'VST';
                });
                if (vm.exciseHinweise) {
                    var exciseTypeIds = _.map(vm.exciseHinweise, function(hinweis) {
                        return hinweis.HinweiseId;
                    })
                    loadEztExciseNomenclature(exciseTypeIds);
                }
            });
    }

    function loadGermanNationalNomenclature(commodityCode) {
        eztDataService.loadEztNomenclature(commodityCode)
            .then(function (eztNomenclature) {
                vm.eztNomenclature = eztNomenclature;
                if (vm.eztNomenclature && vm.eztNomenclature.length === 1) {
                    vm.currentEztNationalCommodityCode = vm.eztNomenclature[0].CommodityCode;
                }
            });
    }

    function selectEztNomenclature(commodityCode) {
        vm.currentEztNationalCommodityCode = commodityCode;
    }

    function loadEztExciseNomenclature(exciseTypeIds) {
        vm.eztExciseNomenclature = [];
        _.forEach(exciseTypeIds, function (exciseTypeId) {
            eztDataService.loadEztExciseNomenclature(exciseTypeId)
                .then(function (eztExciseNomenclature) {
                    vm.eztExciseNomenclature = _.chain(vm.eztExciseNomenclature)
                        .concat(eztExciseNomenclature)
                        .sortBy('Id')
                        .value();
                });
        });
    }

    function onChangeEztExciseCode(eztExciseCode) {
        vm.eztExciseCode = eztExciseCode;
        loadUnits();
    }

    function loadUnits() {
        vm.isLoadingUnits = true;
        var additionalCodes = collectAdditionalCodes();
        var germanExciseCode = vm.eztExciseCode ? vm.eztExciseCode.Id : null;

        return dutyDataService
            .getUnits(vm.good.generalInfo.code, additionalCodes, vm.preferenceCode, germanExciseCode, vm.state.uccModeEnabled)
            .then(function (units) {
                vm.isLoadingUnits = false;
                var newQuantities =_.map(units, function(unit) {
                    var oldQuantity = _.find(vm.quantities, function (quantity) {
                        if (quantity.Unit.code === unit.Unit.code) {
                            if (!quantity.Qualifier && !unit.Qualifier) {
                                return true;
                            }
                            if (quantity.Qualifier && unit.Qualifier && quantity.Qualifier.code === unit.Qualifier.code) {
                                return true;
                            }
                        }
                        return false;
                    });
                    if (oldQuantity) {
                        unit.Amount = oldQuantity.Amount;
                    }
                    else {
                        unit.Amount = null;
                    }
                    return unit;
                });
                vm.quantities = newQuantities;
            });
    }

    function loadPreferenceCodes() {
        var q = $q.defer();
        if (!vm.preferenceCodeTypes || vm.preferenceCodeTypes.length === 0) {
            preferenceCodeDataService.getDescriptions().then (function (preferenceCodes) {
                // TODO: preloaded codes should be stored in a service
                vm.preferenceCodes = _.keyBy(preferenceCodes, 'code');
                // console.log(vm.preferenceCodeTypes);
                q.resolve(preferenceCodes);
            });
        }
        return q.promise;
    }

    function loadAdditionalCodeTypes() {
        var q = $q.defer();
        if (!vm.additionalCodeTypes || vm.additionalCodeTypes.length === 0) {
            additionalCodesDataService.getTypes().then (function (additionalCodeTypes) {
                // TODO: preloaded codes should be stored in a service
                vm.additionalCodeTypes = _.keyBy(additionalCodeTypes, 'code');
                // console.log(vm.additionalCodeTypes);
                q.resolve(additionalCodeTypes);
            });
        }
        return q.promise;
    }

    function loadOptionalNationalAdditionalCodes() {
        var q = $q.defer();
        if (state.filter.referenceCountry === 'NL') {
            if (!vm.optionalNationalAdditionalCodes || vm.optionalNationalAdditionalCodes.length === 0) {
                if (moment(state.filter.date).isBefore('2019-11-30')) {
                    $q.all([
                        additionalCodesDataService.getByType('T'),
                        additionalCodesDataService.getByType('V')
                    ]).then(function(results) {
                        var additionalCodes = _.concat(results[0], results[1]);
                        // TODO: preloaded codes should be stored in a service
                        vm.optionalNationalAdditionalCodes = additionalCodes;
                        q.resolve(additionalCodes);
                    })
                }
                else {
                    additionalCodesDataService.getByType('V').then (function (additionalCodes) {
                        // TODO: preloaded codes should be stored in a service
                        vm.optionalNationalAdditionalCodes = additionalCodes;
                        q.resolve(additionalCodes);
                    });
                }
            }
        }
        return q.promise;
    }

    function loadSection(goodsCode) {
        return nomenclatureDataService.getSectionForCommodityCode(goodsCode)
            .then(function (section) {
                vm.section = section;
                vm.chapter = _.find(section.chapters, function (chapter) {
                    return chapter.code === goodsCode.substring(0, 2) + '00000000';
                });
            });
    }

    function loadDescription(goodscode) {
        return nomenclatureDataService.loadDescription(goodscode)
            .then(function(description) {
                if (description) {
                    var indentation = description.lines[description.lines.length - 1].indent;
                    state.currentIndentation = indentation;
                    vm.good.generalInfo.description = description;
                }
                vm.isLoading.generalInfo = false;
            }, function(err) {
                if (!err.isAborted) {
                    try {
                        Sentry.captureException(err);
                    }
                    catch (error) {
                        try {
                            Sentry.captureMessage(err);
                        }
                        catch (e) {}
                    }
                }
            });
    }

    function languageChanged() {
        if (vm.good.generalInfo.code) {
            loadGood(vm.good.generalInfo.code, vm.nationalAdditionalCode, vm.meursingAdditionalCode, vm.outputCurrency);
        }
    }

    // Subscribe to the languageChanged event to reload the data
    function detached() {
        notificationsService.unsubscribe('filter_movement_changed', movementChanged);
        notificationsService.unsubscribe('filter_date_changed', dateChanged);
        notificationsService.unsubscribe('language_changed', languageChanged);
        if (!state.remember.country && !$location.search().country) {
            state.filter.countryName = null;
            state.filter.countryCode = null;
        }
    }

    function clearGood() {
        vm.good = {
            generalInfo: {
                code: ''
            },
            measures: new goodModelsService.measuresDisplayModel(),
            nomenclatureGroups: []
        };
        vm.customsValueCheckResult = null;
        vm.antiDumpingAdditionalCode = null;
        vm.otherAdditionalTaricCodes = {};
        vm.meursingAdditionalCode = null;
        vm.nationalAdditionalCode = null;
        vm.nationalAdditionalCodeObject = null;
        vm.secondNationalAdditionalCodeObject = null;
        vm.orderNumber = null;
        vm.preferenceCode = null;
        vm.eztNomenclature = null;
        vm.eztHinWeise = null;
        vm.eztExciseHinweise = null;
        vm.currentEztNationalCommodityCode = null;
        vm.eztExciseNomenclature = null;
        vm.eztExciseCode = null;
    }

    function anAdditionalCodeHasBeenSet() {
        return !!vm.nationalAdditionalCodeObject || !!vm.secondNationalAdditionalCodeObject || 
               !!vm.meursingAdditionalCode || !!vm.antiDumpingAdditionalCode ||
                _.some(vm.otherApplicableAdditionalTaricCodes, function(applicableCodesGroupedByType) {
                    return !!vm.otherAdditionalTaricCodes[applicableCodesGroupedByType.type];
                });
    }

    function loadGood(goodsnom, nationalAdditionalCode, meursingAdditionalCode, currency) {
        vm.isLoading.generalInfo = true;
        vm.isLoading.measures = true;
        vm.good.measures = new goodModelsService.measuresDisplayModel();
        goodDataService
            .loadGood(goodsnom, nationalAdditionalCode, meursingAdditionalCode, currency, vm.state.uccModeEnabled)
            .then(loadGoodSuccess, function(err) {
                if (err.status === 400) {
                    $location.url('search/' + goodsnom).replace();
                }
                else if (err.isAborted) {
                    // do nothing; the user may already have navigated to a different page
                }
                else {
                    var heading = goodsnom.substring(0, 4);
                    try {
                        Sentry.captureMessage(err);
                    }
                    catch (e) {}
                    $location.url('search/' + heading).replace();
                }
            });
    }

    function loadNomenclatureGroups(goodsnom) {
        return nomenclatureDataService.loadNomenclatureGroups(goodsnom)
            .then(function(nomenclatureGroups) {
                vm.good.nomenclatureGroups = nomenclatureGroups;
            }, function(err) {
                if (!err.isAborted) {
                    try {
                        Sentry.captureException(err);
                    }
                    catch (e) {}
                }
            });
    }

    function loadGoodSuccess(data) {
        // var indentation = data.description.lines[data.description.lines.length - 1].indent;
        // state.currentIndentation = indentation;
        vm.good.generalInfo.description = data.good.description;
        vm.good.generalInfo.measure_series = data.good.measure_series;
        vm.good.generalInfo.restitutions = data.good.restitutions;
        vm.good.generalInfo.result_valid_on = data.good.result_valid_on;
        vm.good.generalInfo.not_up_to_date = data.good.not_up_to_date;

        vm.good.generalInfo.notes = data.good.notes;

        vm.monetaryUnitsUsedInMeasures = data.monetaryUnitsUsedInMeasures;
        vm.possibleUnitsForCurrencyConversion = data.metadata.possibleUnitsForCurrencyConversion;
        vm.isLoading.generalInfo = false;
        vm.isLoading.measures = false;
        setRecentCode();

        var countriesWithUccPreferenceCodes = ['NL', 'BE', 'SE'];
        var measuresWithUccPreferenceCodes = data.good.measures.filter(m => !!m.ucc_preference_codes);
        if (measuresWithUccPreferenceCodes.length === 0 || countriesWithUccPreferenceCodes.indexOf(state.filter.referenceCountry) === -1) {
            if (state.filter.movement === 'import') {
                uccSettingChanged('no');
            }
            vm.showUccToggle = false;
        }
        else {
            vm.showUccToggle = true;
        }

        loadMeasures(state.filter.countryCode);
    }

    function setRecentCode() {
        if (!vm.good || !vm.good.generalInfo || !vm.good.generalInfo.code)
            return;

        preferences.add({
            name: vm.good.generalInfo.code + '_' + state.filter.movement,
            category: 'recent_codes',
            value: 'goodsnom=' + vm.good.generalInfo.code + '&type=' + state.filter.movement,
            valueType: 'stringValue'
        });
    }

    function loadMeasures(countryCode) {
        vm.validationErrors = {
            quantities: {}
        };
        return goodDataService
            .loadMeasures(countryCode)
            .then(function (measuresResult) {
                loadMeasuresCompleted(measuresResult);
            });
    }

    function loadMeasuresCompleted(measuresResult) {

        vm.hasMeursing = measuresResult.has_meursing;

        var hasAutonomousTariffSuspension = _.some(measuresResult.measures, function (measure) {
            return measure.type_id === '112';
        });

        if (hasAutonomousTariffSuspension) {
            vm.hasAutonomousTariffSuspension = true;
        }

        var measurePreferenceCodesProperty = vm.state.uccModeEnabled === 'yes' ? 'ucc_preference_codes' : 'preference_codes';
        // TODO: convert to objects
        vm.possiblePreferenceCodes = _.chain(measuresResult.measures)
            .filter(function (measure) { return !!measure[measurePreferenceCodesProperty]; })
            .flatMap(function (measure) { return measure[measurePreferenceCodesProperty] })
            .uniqBy(function (preferenceCode) { return preferenceCode })
            .sortBy(function (preferenceCode) { return preferenceCode })
            .map(function (preferenceCode) {
                return _.find(vm.preferenceCodes, function(preferenceCodeObject) {
                    return preferenceCodeObject.code === preferenceCode;
                });
            })
            .value();

        var possibleAdditionalCodes = _.chain(measuresResult.measures)
            .filter(function (measure) { return !!measure.additional_code; })
            .map(function (measure) { return measure.additional_code })
            .uniqBy(function (additionalCode) { return additionalCode.type + additionalCode.code })
            .sortBy(function (additionalCode) { return additionalCode.type + additionalCode.code })
            .value();

        vm.applicableAntiDumpingAdditionalCodes = _.filter(possibleAdditionalCodes, function(additionalCode) {
            return antiDumpingAdditionalCodeTypes.indexOf(additionalCode.type) !== -1;
        });

        // console.log('loadMeasuresCompleted', vm);

        vm.otherApplicableAdditionalTaricCodes = _.chain(possibleAdditionalCodes)
            .filter(function(additionalCode) {
                return otherAdditionalTaricCodeTypes.indexOf(additionalCode.type) !== -1;
            })
            .reduce(function(memo, additionalCode) {
                var codesOfType = _.find(memo, function(codesGroupedByType) {
                    return codesGroupedByType.type === additionalCode.type;
                });
                if (codesOfType === undefined) {
                    memo.push({
                        type: additionalCode.type,
                        codes: [ additionalCode ]
                    });
                }
                else {
                    codesOfType.codes.push(additionalCode);
                }
                return memo;
            }, [])
            .map(function(codeGroup) {
                codeGroup.codes = _.sortBy(codeGroup.codes, function(additionalCode) {
                    return additionalCode.type + additionalCode.code;
                });
                return codeGroup;
            })
            .value();

        // console.log('otherApplicableAdditionalTaricCodes', vm.otherApplicableAdditionalTaricCodes);

        
        vm.applicableNationalAdditionalCodes = _.chain(possibleAdditionalCodes)
            .filter(function(additionalCode) {
                return antiDumpingAdditionalCodeTypes.indexOf(additionalCode.type) === -1 && otherAdditionalTaricCodeTypes.indexOf(additionalCode.type) === -1;
            })
            .sortBy(function (additionalCode) { return additionalCode.type + additionalCode.code; })
            .value();
        
        if (vm.state.filter.referenceCountry === 'SE' || vm.state.filter.referenceCountry === 'IT') {
            var applicableNationalAdditionalCodeTypes = _.chain(vm.applicableNationalAdditionalCodes)
                .map(code => code.type)
                .uniq()
                .sort()
                .value();
            if (applicableNationalAdditionalCodeTypes.length > 1) {
                var lastType = applicableNationalAdditionalCodeTypes[applicableNationalAdditionalCodeTypes.length - 1];
                vm.applicableSecondNationalAdditionalCodes = _.filter(vm.applicableNationalAdditionalCodes, function(additionalCode) {
                    return additionalCode.type === lastType;
                });
                vm.applicableNationalAdditionalCodes = _.filter(vm.applicableNationalAdditionalCodes, function(additionalCode) {
                    return additionalCode.type !== lastType;
                });

            }
        }

        if (vm.applicableNationalAdditionalCodes.length === 0) {
            vm.nationalAdditionalCodeIsOptional = true;
            vm.applicableNationalAdditionalCodes = vm.optionalNationalAdditionalCodes;
        }

        vm.hasAdditionalCodes = vm.hasMeursing ||
            vm.applicableAntiDumpingAdditionalCodes.length > 0 ||
            _.size(vm.otherApplicableAdditionalTaricCodes) > 0 ||
            (vm.state.filter.movement === 'import' && vm.applicableNationalAdditionalCodes && vm.applicableNationalAdditionalCodes.length > 0);

        // console.log('applicableNationalAdditionalCodes', vm.applicableNationalAdditionalCodes);


        // Clear additional codes which are not applicable for the country

        var antiDumpingAdditionalCodeIsApplicable = _.some(vm.applicableAntiDumpingAdditionalCodes, function (applicableCode) {
            return vm.antiDumpingAdditionalCode &&
                vm.antiDumpingAdditionalCode.type === applicableCode.type &&
                vm.antiDumpingAdditionalCode.code === applicableCode.code;
        });

        if (!antiDumpingAdditionalCodeIsApplicable) {
            vm.antiDumpingAdditionalCode = null;
        }

        var preferenceCodeIsApplicable = _.some(vm.possiblePreferenceCodes, function (possiblePreferenceCode) {
            return vm.preferenceCode && vm.preferenceCode.code === possiblePreferenceCode.code;
        });

        if (!preferenceCodeIsApplicable) {
            vm.preferenceCode = null;
        }


        _.forEach(vm.otherAdditionalTaricCodes, function(actualCode, type) {
            var groupedApplicableCodes = _.find(vm.otherApplicableAdditionalTaricCodes, function(group) {
                return group.type === type;
            });
            if (groupedApplicableCodes === undefined) {
                delete vm.otherAdditionalTaricCodes[type];
            }
            else {
                var actualCodeIsApplicable = _.some(groupedApplicableCodes.codes, function (applicableCode) {
                    return actualCode && actualCode.type === applicableCode.type && actualCode.code === applicableCode.code;
                });
                if (!actualCodeIsApplicable) {
                    delete vm.otherAdditionalTaricCodes[type];
                }
            }
        });

        // Filter measures

        var measures = _.filter(measuresResult.measures, function (measure) {
            return measureIsApplicableForAdditionalCode(measure, vm.antiDumpingAdditionalCode);
        });

        measures = _.filter(measures, function (measure) {
            if (vm.state.filter.referenceCountry === 'SE' || vm.state.filter.referenceCountry === 'IT') {
                return measureIsApplicableForAdditionalCode(measure, vm.nationalAdditionalCodeObject) &&
                measureIsApplicableForAdditionalCode(measure, vm.secondNationalAdditionalCodeObject);
            }
            return measureIsApplicableForAdditionalCode(measure, vm.nationalAdditionalCodeObject);
        });

        _.forEach(vm.otherAdditionalTaricCodes, function(additionalCode) {
            measures = _.filter(measures, function (measure) {
                return measureIsApplicableForAdditionalCode(measure, additionalCode);
            });
        });

        var measurePreferenceCodesProperty = vm.state.uccModeEnabled === 'yes' ? 'ucc_preference_codes' : 'preference_codes';
        if (vm.preferenceCode && state.filter.referenceCountry != 'GB') {
            measures = _.filter(measures, function (measure) {
                return !measure[measurePreferenceCodesProperty] ||
                    measure[measurePreferenceCodesProperty].length === 0 ||
                    measure[measurePreferenceCodesProperty].indexOf(vm.preferenceCode.code) > -1;
            });
        }

        var applicableDutyMeasures = _.filter(measures, function (measure) {
            return measure.series === 'C';
        });

        vm.applicableDutyMeasureTypes = _.chain(applicableDutyMeasures)
            .map(function (measure) {
                return {
                    description: measure.description,
                    id: measure.type_id,
                    preference_codes: measure.preference_codes,
                    ucc_preference_codes: measure.ucc_preference_codes,
                    ucc_end_use_procedure_code_44: measure.ucc_end_use_procedure_code_44,
                };
            })
            .groupBy('id')
            .map(function (group) {
                return {
                    description: group[0].description,
                    id: group[0].id,
                    preference_codes: _.chain(group).flatMap('preference_codes').uniq().sort().value(),
                    ucc_preference_codes: _.chain(group).flatMap('ucc_preference_codes').uniq().sort().value(),
                    ucc_end_use_procedure_code_44: group[0].ucc_end_use_procedure_code_44
                };
            })
            .value();

        if (vm.dutyMeasureType) {
            var dutyMeasureTypeIsApplicable = _.some(vm.applicableDutyMeasureTypes, function (measureType) {
                return vm.dutyMeasureType.id === measureType.id;
            });
            if (!dutyMeasureTypeIsApplicable) {
                vm.dutyMeasureType = null;
            }
        }

        if (vm.dutyMeasureType) {
            measures = _.filter(measures, function (measure) {
                return measure.series !== 'C' || measure.type_id === vm.dutyMeasureType.id;
            });
        }

        if (vm.dutyMeasureType || vm.preferenceCode) {
            vm.applicableOrderNumbers = _.chain(measures)
                .filter(function (measure) {
                    return !!measure.ordernumber;
                })
                .map(function (measure) {
                    return measure.ordernumber;
                })
                .uniqBy()
                .value();
        }
        else {
            vm.applicableOrderNumbers = [];
        }



        if (vm.orderNumber && vm.applicableOrderNumbers && vm.applicableOrderNumbers.indexOf(vm.orderNumber) === -1) {
            vm.orderNumber = null;
        }

        if (vm.applicableOrderNumbers && vm.applicableOrderNumbers.length === 1) {
            vm.orderNumber = vm.applicableOrderNumbers[0];
        }

        if (vm.orderNumber) {
            measures = _.filter(measures, function (measure) {
                return measure.series !== 'C' || measure.ordernumber === vm.orderNumber;
            });
        }

        vm.possibleCertificateCodes = _.chain(measures)
            .filter(function (measure) {
                return !!measure.conditions;
            })
            .flatMap(function (measure) {
                return measure.conditions;
            })
            .filter(function (condition) {
                return !!condition.certificate;
            })
            .map(function (condition) {
                return condition.certificate;
            })
            .uniqBy(function (certificate) {
                return certificate.type + certificate.code;
            })
            .sortBy(function (certificate) {
                return certificate.type + certificate.code;
            })
            .value();

        vm.measures = measures;

        if (vm.dutyMeasureType) {
            let possiblePreferenceCodesForDutyMeasureType = _.chain(measures)
                .filter(function (measure) {
                    return measure.type_id === vm.dutyMeasureType.id;
                })
                .flatMap(function (measure) {
                    return measure[measurePreferenceCodesProperty];
                })
                .uniq()
                .value();
            if (possiblePreferenceCodesForDutyMeasureType.length === 1) {
                vm.preferenceCode = _.find(vm.possiblePreferenceCodes, function(p) {
                    return p.code === possiblePreferenceCodesForDutyMeasureType[0];
                });
            }
        }

        vm.good.numberOfMeasures = vm.measures.length;

        vm.good.measures = measureGroupingService.group(measures, state.filter.referenceCountry);
    }

    function measureIsApplicableForAdditionalCode(measure, additionalCode) {
        if (!additionalCode) {
            return true;
        }
        if (!measure.additional_code) {
            return true;
        }
        if (measure.additional_code.type !== additionalCode.type) {
            if (antiDumpingAdditionalCodeTypes.indexOf(measure.additional_code.type) > -1 && antiDumpingAdditionalCodeTypes.indexOf(additionalCode.type) > -1) {
                return false;
            }
            if (nationalAdditionalCodeTypes.indexOf(measure.additional_code.type) > -1 && nationalAdditionalCodeTypes.indexOf(additionalCode.type) > -1) {
                if (vm.state.filter.referenceCountry === 'SE' || vm.state.filter.referenceCountry === 'IT') {
                    return true;
                }
                return false;
            }
            return true;
        }

        if (measure.additional_code.type === additionalCode.type && measure.additional_code.code === additionalCode.code) {
            return true;
        }

        return false;
    }


    function movementChanged() {
        if (state.filter.movement === 'import') {
            $location.url('/search/' + vm.good.generalInfo.code);
        }
        else {
            vm.state.currentIndentation = null; // we're changing the goods code, so we should reset the indentation, too
            $location.url('/search/' + vm.good.generalInfo.code.substr(0, 8) + '00');
        }
    }

    function loadMeursingTariffs() {
        loadGood(vm.good.generalInfo.code, vm.nationalAdditionalCode, vm.meursingAdditionalCode, vm.outputCurrency);
    }

    function showMeursingAdditionalCodeModal () {
        goodModals.meursing
            .show()
            .result
            .then(function (result) {
                if (!result && !result.code)
                    return;
                vm.meursingAdditionalCode = '7' + result.code;
                loadMeursingTariffs();
            })
            .catch(function (res) {
                if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    throw res;
                }
            });
    }

    return vm;
}