module.exports = [
    'common', 
    'dataContext', 
    'applicationStateService',
    usTariffDataService
];

function usTariffDataService(
    common, 
    dataContext,
    state
) {
    var service = {
        loadTariffData: loadTariffData,
        getMinDate: getMinDate
    };
    return service;

    function loadTariffData(commodityCode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/us-trade-tariff/measures/' + commodityCode +
            '?movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }

        return dataContext.get(url);
    }

    function getMinDate() {
        var url = common.config.apiHosts[common.config.currentApi] + '/us-trade-tariff/minimum-date';
        return dataContext.get(url);
    }
}