﻿module.exports = {
    bindings: {
        footnotes: '<'
    },
    controller: footnoteButtonsController,
    templateUrl: 'footnotes/components/footnote-buttons.html'
};

footnoteButtonsController.$inject = [
    'common', 
    'eztDataService',
    'footnoteDataService', 
    'footnoteModalsService'
];

function footnoteButtonsController(
    common, 
    eztDataService, 
    footnoteData, 
    footnoteModals
) {

    var vm = {
        $onInit: attached,
        footnotes: [],
        footnotesByType: [],
        processFootnotes: processFootnotes,
        showFootnote: showFootnote
    };

    function attached() {
        vm.footnotesByType = processFootnotes(vm.footnotes);
    }

    function processFootnotes(footnotes) {
        if (!footnotes || footnotes.length == 0)
            return null;

        var footnotesByType = _.reduce(footnotes, function (accumulator, footnote) {
            if (accumulator[footnote.type] === undefined) {
                accumulator[footnote.type] = [footnote.code];
            }
            else {
                accumulator[footnote.type].push(footnote.code);
            }
            return accumulator;
        }, []);

        footnotesByType = _.mapValues(footnotesByType, function(codes, type) {
            if (codes && codes.length >= 5) {
                return [codes.length + (common.language.get() === 'nl' ? ' voetnoten' : ' footnotes')];
            }
            return codes;
        });

        return footnotesByType;
    }

    function showFootnote(footnote, footnotes) {
        return common.$q.all(_.map(footnotes, function(fn) {
            if (fn.type === "TS" || fn.origin === 'IT' || fn.origin === 'US') {
                return fn;
            }
            if (fn.origin === 'DE') {
                return eztDataService.loadEztFootnote(fn.type, fn.code);
            }
            else {
                return footnoteData.getFootnote(fn.type, fn.code);
            }
        }))
            .then(function(footnotesWithDetails) {
                footnoteModals.footnote.show(footnote, footnotesWithDetails);
            });
    }

    return vm;
}