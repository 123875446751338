angular.module('taric.us', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('usMeasuresPage', require('./components/us-measures-page'))
    // Modals
    // Services
    .factory('usTariffDataService', require('./services/us-tariff-data'))
    ;


function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/us-measures/:commodityCode', {
            template: '<us-measures-page></us-measures-page>'
        })
    ;
}
