﻿module.exports = ['$cookies', '$q', '$rootScope', identityService];

function identityService($cookies, $q, $rootScope) {
    var service = {
        init: getFromCookie,
        getAllTradeTariffs: getAllTradeTariffs,
        getAllowedTradeTariffs: getAllowedTradeTariffs,
        getDisallowedTradeTariffs: getDisallowedTradeTariffs,
        hasPermission: hasPermission,
        isAuthenticated: false,
        permissions: [],
        rememberMe: false,
        set: set,
        token: {
            tokenValue: null,
            tokenExpiresOn: null,
            tokenIssuedOn: null
        },
        updateCookie: updateCookie,
        removeCookie: removeCookie,
        userInfo: null
    };

    function getFromCookie() {
        var deferred = $q.defer();
        var token = $cookies.getObject('authToken');
        if (token !== undefined && token !== null && token !== '') {
            service.isAuthenticated = true;
            service.token = token;
            deferred.resolve(true);
        }
        else {
            deferred.resolve(false);
        }
        return deferred.promise;
    }

    function hasPermission(name) {
        if (!name)
            return false;
        name = name.toLowerCase();
        var invertResult = name.indexOf('!') === 0;
        if (invertResult) {
            name = name.substring(1);
        }

        if (name.indexOf(',') > -1) {
            var names = name.split(',');
            for (var i = 0; i < names.length; i++) {
                if (_.indexOf(service.permissions, names[i]) > -1) {
                    return invertResult ? false : true;
                }
            }
            return invertResult ? true : false;
        }
        else {
            var hasPermission = _.indexOf(service.permissions, name) > -1;
            return invertResult ? !hasPermission : hasPermission;
        }
    }

    function getAllTradeTariffs() {
        var tradeTariffs = ['EU', 'DE', 'BE', 'NL', 'GB', 'CH', 'SE', 'IT', 'US'];
        return tradeTariffs;
    }

    function getDisallowedTradeTariffs() {
        return _.difference(getAllTradeTariffs(), getAllowedTradeTariffs());
    }

    function getAllowedTradeTariffs() {
        var allowedTradeTariffs = _.chain(service.permissions)
            .filter(function (permission) {
                return _.startsWith(permission, 'trade_tariff_');
            })
            .map(function (permission) {
                return permission.substring(13).toUpperCase();
            })
            .value();
        return _.intersection(getAllTradeTariffs(), allowedTradeTariffs);
    }

    function set(username, data, rememberMe) {
        var date = getExpiryDate(rememberMe);
        if (!data) {
            service.isAuthenticated = false;
            service.permissions = [];
            service.token.tokenValue = null;
            service.token.tokenExpiresOn = null;
            service.token.tokenIssuedOn = null;
            service.username = null;
            $rootScope.$emit('identity:clear');
            $cookies.remove('authToken');
        }
        else {
            service.rememberMe = rememberMe;
            service.isAuthenticated = true;
            service.token = {
                rememberMe: !!rememberMe,
                tokenValue: data.access_token,
                tokenExpiresOn: data['expires_on'],
                tokenIssuedOn: data['issued_on']
            };
            service.userInfo = data;
            $cookies.putObject('authToken', service.token, { 'expires': date });
            $rootScope.$emit('identity:set');
        }
    }

    function getExpiryDate(rememberMe) {
        var date = new Date();
        if (rememberMe) {
            // add two weeks to date
            date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
        } else {
            // add 20 minutes to date
            date.setTime(date.getTime() + (20* 60 * 1000));
        }
        return date;
    }

    function updateCookie() {
        var cookie = $cookies.getObject('authToken');
        var date = getExpiryDate(cookie.rememberMe);
        $cookies.putObject('authToken', cookie,  { 'expires': date });
    }

    function removeCookie() {
        $cookies.remove('authToken');
    }

    return service;
}
