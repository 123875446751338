module.exports = {
    bindings: {
        heading: '<',
        hasAtrs: '<',
        hasBtis: '<',
        hasCaseLaw: '<',
        hasEcics: '<',
        searchQuery: '<',
        onClickHeading: '&',
        onDoubleClickHeading: '&'
    },
    controller: headingController,
    templateUrl: 'nomenclature/components/nomenclature-heading.html'
};

headingController.$inject = ['applicationStateService'];

function headingController(state) {
    var vm = {
        $onInit: attached,
        state: state,
        clickHeading: clickHeading,
        doubleClickHeading: doubleClickHeading
    };
    return vm;

    function attached() {
        vm.chapterId = vm.heading.value.commodityCode.substring(0, 2);
    }

    function clickHeading() {
        vm.onClickHeading({ heading: vm.heading });
    }

    function doubleClickHeading() {
        vm.onDoubleClickHeading({ heading: vm.heading });
    }
}