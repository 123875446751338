﻿module.exports = [addHyperlinksFilter];

function addHyperlinksFilter() {
    function makeReplacementFunction(replaceRegex, replaceTemplate) {
        return function (str, searchRegex) {
            return str.replace(searchRegex, function () {
                var matchedString = arguments[0]; // Eg. posten xxxx en xxxx
                var result = matchedString.replace(replaceRegex, replaceTemplate);
                return result;
            });
        };
    }

    var options = {
        'heading': {
            'searchRegex': /((?:(?:[H|h]eading(?:s)?)|(?:[Ss]ubheading(?:s(?:\sof)?)?)|(?:[Tt]ariff items?)|(?:[Ss]tatistical reporting numbers?)|(?:CN(?:\s|-)codes?))(?:\s|\n|:|<br\/?>|&nbsp;)*)(?:(?:ex)?(?:\s|\n|<br\/?>|&nbsp;)*)?(\d{2}(?:(?:[.\s]|&nbsp;)?\d{2})+)(?:(?:(?:\s|\n|<br\/?>)*(?:;|,|,?\s?and|,?\s?or|to|through|-)(?:\s|\n|<br\/?>|&nbsp;)*(\d{2}(?:(?:[.\s]|&nbsp;)?\d{2})+)))*/g,
            'replaceRegex': /(\d{2}(?:(?:[.\s]|&nbsp;)?\d{2})+)/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/search/$1" target="_blank">$1</a>',
        },
        'heading_FR': {
            'searchRegex': /((?:(?:[N|n]<sup>o(?:<\/sup><sup>)?s?<\/sup>\$?)|(?:[S|s]ous-position(?:s)?)|n°)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{2}(?:[.\s]?\d{2})+)(?:(?:(?:\s|\n|<br\/?>|&nbsp;)*(?:;|,|et|ou|à|-)(?:\s|\n|<br\/?>|&nbsp;)*(\d{2}(?:[.\s]?\d{2})+)))*/g,
            'replaceRegex': /(\d{2}(?:[.\s]?\d{2})+)/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/search/$1" target="_blank">$1</a>',
        },
        'heading_DE': {
            'searchRegex': /((?:(?:Pos(?:\.|ition|itionen)?)|(?:Unterpos(?:\.|ition|itionen)?)|KN-Codes?)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{2}(?:(?:\s|\n|<br\/?>|&nbsp;)*\d{2})+)(?:(?:(?:\s|\n|<br\/?>|&nbsp;)*(?:;|,|und|oder|bis|bzw\.|-)(?:\s|\n|<br\/?>|&nbsp;)*(\d{2}(?:(?:\s|\n|<br\/?>|&nbsp;)*\d{2})+)))*/g,
            'replaceRegex': /(\d{2}(?:(?:\s|\n|<br\/?>|&nbsp;)*\d{2})+)/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/search/$1" target="_blank">$1</a>',
        },
        'post': {
            'searchRegex': /((?:(?:[P|p]ost(?:en)?)|(?:[O|o]nderverdeling(?:en)?)|(?:GN\s?-\s?codes?))(?:\s|\n|<br\/?>|&nbsp;)*)(\d{2}(?:[.\s]?\d{2})+)(?:(?:(?:\s|\n|<br\/?>|&nbsp;)*(?:;|,|en|of|t\/m|tot en met|-)(?:\s|\n|<br\/?>|&nbsp;)*(\d{2}(?:[.\s]?\d{2})+)))*/g,
            'replaceRegex': /(\d{2}(?:[.\s]?\d{2})+)/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/search/$1" target="_blank">$1</a>',
        },
        'post2': { // in explanatory notes (versie 2015)
            'searchRegex': /<span class=['"]bold['"]>\s*\n?\d{2}(?:(?:[.\s]|&nbsp;)?\d{2})+(?:(?:\s(?:;|,|en|of|t\/m|tot en met|and|or|to|through|und|oder|bis|bzw\.|et|ou|à|-)\s\d{2}(?:(?:[.\s]|&nbsp;)?\d{2})+))*\s*<\/span>/g,
            'replaceRegex': /(\d{2}(?:(?:[.\s]|&nbsp;)?\d{2})+)/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/search/$1" target="_blank">$1</a>',
        },
        'code_tarifering': { // in tariferingen
            'searchRegex': /\d{4}(?:[.\s]?\d{2}){0,3}<\/h5>/g,
            'replaceRegex': /(\d{4}(?:[.\s]?\d{2})+)/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/search/$1" target="_blank">$1</a>',
        },
        'hoofdstuk': {
            'searchRegex': /((?:[H|h]oofdstuk(?:ken)?)(?:\s|\n|<br\/?>)*)(\d{1,2})(?:(?:((?:\s|\n|<br\/?>|&nbsp;)*(?:;|,|en|of|t\/m|tot en met|-)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{1,2})))*/g,
            'replaceRegex': /(\d{1,2})/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/chapter/$1" target="_blank">$1</a>'
        },
        'chapter': {
            'searchRegex': /((?:[C|c]hapter(?:s)?)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{1,2})(?:(?:((?:\s|\n|<br\/?>|&nbsp;)*(?:;|,|and|or|to|through|-)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{1,2})))*/g,
            'replaceRegex': /(\d{1,2})/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/chapter/$1" target="_blank">$1</a>'
        },
        'kapitel': {
            'searchRegex': /((?:K[Aa][Pp](?:\.|itel)(?:s|n)?)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{1,2})(?:(?:((?:\s|\n|<br\/?>|&nbsp;)*(?:;|,|und|oder|bis|bzw\.|-)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{1,2})))*/g,
            'replaceRegex': /(\d{1,2})/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/chapter/$1" target="_blank">$1</a>'
        },
        'chapitre': {
            'searchRegex': /((?:[C|c]hapitre(?:s)?)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{1,2})(?:(?:((?:\s|\n|<br\/?>|&nbsp;)*(?:;|,|et|ou|à|-)(?:\s|\n|<br\/?>|&nbsp;)*)(\d{1,2})))*/g,
            'replaceRegex': /(\d{1,2})/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/chapter/$1" target="_blank">$1</a>'
        },
        'afdeling': {
            'searchRegex': /([A|a]fdeling(?:en)?) ([IVX]{1,5})(?: (;|en|of|t\/m|tot en met|,|-) ([IVX]{1,5}))?/g,
            'replaceRegex': /([IVX]{1,5})/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/sections" target="_blank">$1</a>'
        },
        'abschnitt': {
            'searchRegex': /(A[Bb][Ss](?:\.|chnitt)(?:s)?) ([IVX]{1,5})(?: (;|und|oder|bis|,|bzw\.|-) ([IVX]{1,5}))?/g,
            'replaceRegex': /([IVX]{1,5})/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/sections" target="_blank">$1</a>'
        },
        'section': {
            'searchRegex': /([S|s]ections?)(?:\s|&nbsp;)([IVX]{1,5})(?:(?:\s|&nbsp;)(;|,|and|or|to|through|et|ou|à|-)(?:\s|&nbsp;)([IVX]{1,5}))?/g,
            'replaceRegex': /([IVX]{1,5})/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/sections" target="_blank">$1</a>'
        },
        'hyperlink': {
            'searchRegex': /(http:\/\/.*?(?:\.html?|:PDF))/g,
            'replaceRegex': /(http:\/\/.*?(?:\.html?|:PDF))/g,
            'replaceTemplate': '<a class="link-commodity-code" href="$1" target="_blank">$1</a>'
        },
        'see': {
            'searchRegex': /^See \d{4}(?:[.\s]?\d{2}){0,3}(?:(?:(?:\s|\n|<br\/?>)*(?:;|,|and|or|to|through|-)(?:\s|\n|<br\/?>|&nbsp;)*(\d{2}(?:(?:[.\s]|&nbsp;)?\d{2})+)))*/g,
            'replaceRegex': /(\d{4}(?:[.\s]?\d{2})+)/g,
            'replaceTemplate': '<a class="link-commodity-code" href="/#!/search/$1" target="_blank">$1</a>',
        }
    };


    options.hoofdstuk.filter = makeReplacementFunction(options.hoofdstuk.replaceRegex, options.hoofdstuk.replaceTemplate);
    options.kapitel.filter = makeReplacementFunction(options.kapitel.replaceRegex, options.kapitel.replaceTemplate);
    options.chapter.filter = makeReplacementFunction(options.chapter.replaceRegex, options.chapter.replaceTemplate);
    options.chapitre.filter = makeReplacementFunction(options.chapitre.replaceRegex, options.chapitre.replaceTemplate);
    options.abschnitt.filter = makeReplacementFunction(options.abschnitt.replaceRegex, options.abschnitt.replaceTemplate);
    options.afdeling.filter = makeReplacementFunction(options.afdeling.replaceRegex, options.afdeling.replaceTemplate);
    options.section.filter = makeReplacementFunction(options.section.replaceRegex, options.section.replaceTemplate);
    options.hyperlink.filter = makeReplacementFunction(options.hyperlink.replaceRegex, options.hyperlink.replaceTemplate);
    options.see.filter = makeReplacementFunction(options.see.replaceRegex, options.see.replaceTemplate);

    options.post.filter = function (str, regex) {

        return str.replace(regex, function () {
            var matchedString = arguments[0];
            var result = matchedString.replace(/(\d{2}(?:(?:[.\s]|&nbsp;)*\d{2})+)/g, function (postString) {
                var post = postString.replace(/[\s.]/g, '');
                var prettyPost = post.substring(0, 4);
                if (post.length > 4) {
                    prettyPost += '&nbsp;' + post.substring(4, 6);
                }
                if (post.length > 6) {
                    prettyPost += '&nbsp;' + post.substring(6, 8);
                }
                if (post.length > 8) {
                    prettyPost += '&nbsp;' + post.substring(8, 10);
                }
                return '<a class="link-commodity-code" href="/#!/search/' + post + '" target="_blank">' + prettyPost + '</a>';
            });
            return result;
        });
    };

    options.post2.filter = makeReplacementFunction(options.post2.replaceRegex, options.post2.replaceTemplate);
    options.heading.filter = makeReplacementFunction(options.heading.replaceRegex, options.heading.replaceTemplate);
    options.heading_DE.filter = makeReplacementFunction(options.heading_DE.replaceRegex, options.heading_DE.replaceTemplate);
    options.heading_FR.filter = makeReplacementFunction(options.heading_FR.replaceRegex, options.heading_FR.replaceTemplate);
    options.code_tarifering.filter = makeReplacementFunction(options.code_tarifering.replaceRegex, options.code_tarifering.replaceTemplate);


    return function (str) {

        if (!str)
            return '';

        _.each(_.values(options), function (option) {
            str = option.filter(str, option.searchRegex);
        });

        return str;
    };
}