﻿var moment = require('moment');

module.exports = {
    controller: navbarController,
    templateUrl: 'widgets/components/navbar.html'
};

navbarController.$inject = ['$analytics', '$location', 'common', 'documentationService', 'favoritesService', 'preferenceService', 'applicationStateService'];

function navbarController($analytics, $location, common, documentationService, favoriteService, preferences, state) {
    var vm = {
        config: common.config,
        identity: common.identity,
        favoriteService: favoriteService,
        language: common.language,
        openFavorite: openFavorite,
        removeFavorite: removeFavorite,
        setLanguage: setLanguage,
        showReferenceDatePicker: showReferenceDatePicker,
        showReferenceDateNotification: showReferenceDateNotification,
        showSidebar: false,
        supportedLanguages: common.language.supportedLanguages,
        state: state,
        toggle: toggle,

    };

    function toggle() {
        vm.showSidebar = !vm.showSidebar;
        if (vm.showSidebar) {
            $analytics.eventTrack('open-main-nav', { category: 'navigation' })
        }
        documentationService.initialize('menu');
    }

    function showReferenceDateNotification() {
        return showReferenceDatePicker() && state.filter.date !== moment().format('YYYY-MM-DD');
    }

    function showReferenceDatePicker() {
        if ($location.path().indexOf('/case-law') === 0) {
            return false;
        }
        if ($location.path().indexOf('/compliance-check') === 0) {
            return false;
        }
        if ($location.path().indexOf('/account') === 0) {
            return false;
        }
        if ($location.path().indexOf('/correlation-table') === 0) {
            return false;
        }
        return true;
    }

    function openFavorite(goodscode, movement, $event) {
        if (!movement) {
            movement = 'import';
        }
        state.filter.movement = movement;
        preferences.replace({
            name: 'movement',
            category: 'user_preferences',
            value: movement,
            valueType: 'stringValue'
        });
        vm.showSidebar = false;
        $location.url('/search/' + goodscode);
    }

    function removeFavorite(goodscode, movement, $event) {
        favoriteService.remove(goodscode, movement);
        $event.stopPropagation();
    }

    function setLanguage(lang) {
        preferences.setLanguage(lang);
    }

    return vm;
}
