module.exports = {
    controller: usMeasuresPageController,
    templateUrl: 'us/components/us-measures-page.html'
};

usMeasuresPageController.$inject = [
    '$analytics',
    '$filter',
    '$location',
    '$routeParams', 
    'common',
    'applicationStateService',
    'countryDataService',
    'nomenclatureDataService',
    'notificationsService', 
    'preferenceService', 
    'usTariffDataService', 
];

function usMeasuresPageController(
    $analytics, 
    $filter,
    $location,
    $routeParams,
    common,
    state,
    countryDataService,
    nomenclatureDataService,
    notifications,
    preferences,
    usTariffDataService
) {
    
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        countryPickerSelectionChanged: countryPickerSelectionChanged,
        input: {},
        state: state,
        tariffData: null,
        thirdCountries: countryDataService.ergaOmnesCountries,
        validationErrors: {},
        isLoading: true
    };

    return vm;

    function countryPickerSelectionChanged(country, rememberCountry) {
        var countryHasChanged = state.countryHasChanged(country);
        state.updateCountryFilter(country, rememberCountry);
        if (rememberCountry && country) {
            preferences.saveCountryPreference(country);
        } else {
            preferences.clearCountryPreference();
        }
        if (countryHasChanged) {
            if (country) {
                vm.tariffData.filteredDuties = filterGroupedDutiesByCountry(vm.tariffData.groupedDuties, country.code);
                vm.tariffData.filteredAdditionalDuties = filterGroupedDutiesByCountry(vm.tariffData.groupedAdditionalDuties, country.code);
            }
            else {
                vm.tariffData.filteredDuties = vm.tariffData.groupedDuties;
                vm.tariffData.filteredAdditionalDuties = vm.tariffData.groupedAdditionalDuties;
            }
        }
    }

    function filterGroupedDutiesByCountry(groupedDuties, countryCode) {
        if (!countryCode) 
        {
            return groupedDuties;
        }
        return groupedDuties
            .filter(groupedDuty =>
                groupedDuty.dutyRatesWithMeasures.some(dutyRateWithMeasures =>
                    $filter('hasCountry')(dutyRateWithMeasures.measures, countryCode).length > 0
                )
            )
            .map(groupedDuty => {
                return {
                    dutyRate: groupedDuty.dutyRate,
                    dutyRatesWithMeasures: groupedDuty.dutyRatesWithMeasures
                        .filter(dutyRateWithMeasures =>
                            $filter('hasCountry')(dutyRateWithMeasures.measures, countryCode).length > 0
                        )
                        .map(dutyRateWithMeasures => {
                            return {
                                dutyRate: dutyRateWithMeasures.dutyRate,
                                measures: $filter('hasCountry')(dutyRateWithMeasures.measures, countryCode)
                            };
                        })
                };
            });
    }

    function attached() {
        if (!$routeParams.commodityCode) {
            return;
        }
        // TODO: it may be better to create a component for the US tariff data,
        // so that we can re-use the same url as for the other trade tariffs
        if (state.filter.referenceCountry !== 'US') {
            $location.path('/good/' + $routeParams.commodityCode);
        }
        $analytics.pageTrack('/usa-measures');
        vm.input.commodityCode = $routeParams.commodityCode;
        loadTariffData($routeParams.commodityCode);
        notifications.subscribe('filter_date_changed', dateChanged);
        notifications.subscribe('filter_movement_changed', movementChanged);
        notifications.subscribe('language_changed', languageChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
        notifications.unsubscribe('filter_movement_changed', movementChanged);
        notifications.unsubscribe('language_changed', languageChanged);
    }

    function dateChanged() {
        loadTariffData($routeParams.commodityCode);
    }

    function movementChanged() {
        $location.url('/search/' + $routeParams.commodityCode);
    }

    function languageChanged() {
        loadTariffData($routeParams.commodityCode);
    }

    function loadTariffData(commodityCode) {
        vm.isLoading = true;
        return nomenclatureDataService.isDeclarable(commodityCode)
            .then(function(isDeclarable) {
                if (!isDeclarable) {
                    $location.url('search/' + commodityCode);
                }
                else {
                    common.$q.all([
                        usTariffDataService.loadTariffData(commodityCode)
                            .then(function (tariffData) {
                                vm.tariffData = tariffData;
                                vm.tariffData.filteredDuties = filterGroupedDutiesByCountry(tariffData.groupedDuties, state.filter.countryCode);
                                vm.tariffData.filteredAdditionalDuties = filterGroupedDutiesByCountry(tariffData.groupedAdditionalDuties, state.filter.countryCode);
                                return tariffData;
                            })
                    ]).then(function(results) {
                        vm.isLoading = false;
                    });
                }
            })
    }


}
