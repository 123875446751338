﻿module.exports = ['common', 'dataContext', '$q', 'applicationStateService', 'notificationsService', countryDataService];

function countryDataService(common, context, $q, state, notifications) {

    var service = {
        countries: null,
        ergaOmnesCountries: null,
        regions: null, // list of country codes per region
        getCountry: getCountry,
        getCountries: getCountries,
        preloadCountries: preloadCountries,
        getEuCountries: getEuCountries,
        preloadRegions: preloadRegions,
        init: init
    };

    notifications.subscribe('filter_date_changed', init);

    return service;

    function init() {
        return $q.all([ preloadCountries(), preloadRegions() ])
            .then(function() {
                return initErgaOmnesCountries()
            });
    }

    function getErgaOmnesRegionCode() {
        if (state.filter.referenceCountry == 'CH') {
            return '200000';
        }
        else if (state.filter.referenceCountry == 'US') {
            return 'ALL';
        }
        return '1011';
    }

    function initErgaOmnesCountries() {
        var ergaOmnesRegionCode = getErgaOmnesRegionCode();
        service.ergaOmnesCountries = _.chain(service.regions[ergaOmnesRegionCode])
            .map(function(countryCode) {
                return {
                    code: countryCode,
                    name: service.countries[countryCode],
                    searchDisplay: countryCode + ' — ' + service.countries[countryCode]
                };
            }).filter(function(country) {
                return country.name !== undefined && (country.code === 'EU' || country.code !== state.filter.referenceCountry);
            }).sortBy(function(country) {
                return country.name;
            }).value();
    }

    function getCountry(countryCode) {
        if (countryCode === null || countryCode === undefined)
            return countryCode;
        if (service.countries === null)
            service.preloadCountries();
        return service.countries[countryCode];
    }

    function getEuCountries(q) {
        if (state.filter.referenceCountry == 'CH') {
            q.regionCode = '100001';
        }
        else {
            q.regionCode = '1010';
        }
        return getCountries(q);
    }

    function getCountries(q) {
        var url = common.config.apiHosts[common.config.currentApi] + '/countries' +
            '?referenceCountry=' + q.referenceCountry +
            '&lang=' + common.language.get() +
            '&date=' + state.filter.date;
        if (q.regionCode) {
            url += '&region=' + q.regionCode;
        }
        return context.get(url);
    }

    function preloadCountries(regionCode) {
        var qCountriesLoaded = common.$q.defer();

        var url = common.config.apiHosts[common.config.currentApi] + '/countries' +
            '?referenceCountry=' + state.filter.referenceCountry +
            '&lang=' + common.language.get() +
            '&date=' + state.filter.date;
        if (regionCode) {
            url += '&region=' + regionCode;
        }
        context.get(url).then(function (data) {
            service.countries = {};
            _.forEach(data, function (country) {
                if (!service.countries[country.code]) {
                    service.countries[country.code] = country.name;
                }
            });
            qCountriesLoaded.resolve();
        });

        return qCountriesLoaded.promise;
    }

    function preloadRegions() {
        var qRegionsLoaded = common.$q.defer();

        // TODO: update this to be ready for the situation after Brexit so the UK version doesn't automatically get all EU regions
        var url = common.config.apiHosts[common.config.currentApi] + '/regions/members' +
            '?origin=EU,' + state.filter.referenceCountry +
            '&referenceCountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;
        context.get(url).then(function (data) {
            service.regions = {};
            _.forEach(data, function (region) {
                if (!service.regions[region.code]) {
                    service.regions[region.code] = region.countryCodes;
                }
            });
            qRegionsLoaded.resolve();
        });

        return qRegionsLoaded.promise;
    }
}
