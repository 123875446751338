var moment = require('moment');

module.exports = [
    'applicationStateService', 
    'notificationsService', 
    referenceDateService
];

function referenceDateService(
    state, 
    notifications
) {
    var service = {
        getReferenceDate: getReferenceDate,
        setReferenceDate: setReferenceDate
    };
    return service;

    function getReferenceDate() {
        return moment(state.filter.date, 'YYYY-MM-DD').toDate();
    }

    function setReferenceDate(newDate) {
        if (newDate) {
            var serializedDate = moment(newDate).format('YYYY-MM-DD');
            if (serializedDate !== state.filter.date) {
                state.filter.date = serializedDate;
                notifications.publish('filter_date_changed');
            }
        }
    }
}